import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import Accordion from '../components/Accordion/Accordion';
import CtrSection from '../components/CtrSection/CtrSection';
import FeatureSectionLeft from '../components/FeatureSectionLeft/FeatureSectionLeft';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';
import HeroSection from '../components/HeroImageSection/HeroSection';
import Layout from '../components/layout';
import ReasonSection from '../components/ReasonSection/ReasonSection';
import Seo from '../components/seo';

const CorporateIndex = ({ data, location }) => {
  const { nanoCms } = useStaticQuery(graphql`
    query {
      nanoCms {
        articles(isPublished: true, category: "お知らせ", limit: 3) {
          edges {
            node {
              title
              url
              updatedAt
            }
          }
        }
      }
    }
  `);

  // GraphQLデータからニュース項目を生成
  const newsItems = nanoCms.articles.edges.map(({ node }) => ({
    id: node.url, // 一意のIDが必要な場合、URLをIDとして使用
    postDate: node.updatedAt, // 投稿日
    content: node.title, // コンテンツ(タイトル)
    url: `/articles/${node.url}/`, // URL
  }));

  return (
    <Layout>
      <HeroSection
        imagePath={'/hojojo-top.webp'}
        ctaLink={'/contact'}
        buttonText={'お問い合わせ'}
      />
      <ReasonSection />
      <FeatureSectionLeft
        imageSrc="/hojojo1.jpg"
        title="補助金とは"
        description="補助金は、経済産業省から支給される返済不要の給付金です。
雇用や労働環境の整備・改善、労務問題のほか
事業上の課題解決における資金面のサポートが主な目的です。"
        sectionId="section1"
      />
      <FeatureSectionRight
        imageSrc="/hojojo2.jpg"
        imageAlt="Example image"
        title="助成金とは"
        description="助成金とは、厚生労働省から支給される返済不要の給付金です。
企業や個人事業主に対して資金面でサポートし、新規事業や創業促進
等、国や地方自治体等が掲げる行政目的の達成が主な目的です。"
      />
      <Accordion
        title="よくある質問"
        subTitle="FAQ"
        sectionId="accordion"
        colorType="primary"
        items={[
          {
            question: '補助金・助成金にはどんな違いがあるのでしょうか？',
            answer: `補助金は特定の事業やプロジェクトに対して交付される資金で、成果に基づいて支給されます。
            一般的に自己資金も必要です。助成金は特定の条件を満たす団体や個人に支給され、より幅広い分野で利用でき、
            申請が通れば比較的自由に使える資金です。
          `,
          },
          {
            question:
              '補助金や助成金を申請するにはどのような条件がありますか？',
            answer: `補助金や助成金の条件は、各プログラムや団体によって異なります。
            例えば、特定の業種や規模の企業、個人事業主が対象になる場合や、特定の地域で事業を行っていること、
            一定の売上や従業員数の制限がある場合があります。
            申請する前に、条件を確認することが重要です。`,
          },
          {
            question: '補助金・助成金を受け取った場合、返済は必要ですか？',
            answer: `補助金や助成金は基本的に返済不要ですが、交付された目的に従わなかった場合や、
            虚偽の申請があった場合には返還を求められることがあります。
            また、報告義務がある場合も多いので、適切に使われたかどうかの確認が行われることがあります。`,
          },
          {
            question: '補助金や助成金を申請するために必要な書類は何ですか？',
            answer: `一般的に必要な書類には事業計画書や収支計画書、法人登記簿謄本や納税証明書などがあります。 申請する補助金・助成金によって必要書類は異なるので、 必ず募集要項や申請ガイドラインを確認し、不明な点は遠慮なくお問い合わせください。`,
          },
          {
            question:
              '補助金・助成金は申請したら必ず受け取れるものでしょうか？',
            answer: `補助金や助成金は種類によって倍率や採択率が異なるため、全ての申請が通るとは限りません。審査基準は一般公開されていないため、条件を確認して申請書類を作成する必要があります。。
          `,
          },
        ]}
      />
      <CtrSection />
    </Layout>
  );
};

export default CorporateIndex;

export const Head = () => (
  <Seo description="補助金・助成金ドットコムは補助金助成金の情報提供、顧問サービスを提供しています。補助金や助成金の顧問制度を利用すれば、確実に申請手続きがスムーズになります。記事も随時更新していますので、チェックしてください。" />
);
